import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19')
];

export const server_loads = [];

export const dictionary = {
		"/": [~3],
		"/article/[eventSlug]": [~5],
		"/checkout": [6,[2]],
		"/checkout/confirm-appointment": [7,[2]],
		"/checkout/confirm-email": [8,[2]],
		"/checkout/event-occupied": [9,[2]],
		"/checkout/form": [10,[2]],
		"/checkout/success": [11,[2]],
		"/checkout/summary": [12,[2]],
		"/checkout/ticket-selection": [13,[2]],
		"/profile": [14],
		"/rechtstext/kickerturnier": [15],
		"/rechtstext/konzertkarten": [16],
		"/rechtstext/samsungfanwochen": [17],
		"/rechtstext/tippspiel": [18],
		"/rechtstext/vrkickerturnier": [19],
		"/[eventSlug]": [~4]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.svelte';